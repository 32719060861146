import React, {useState, useEffect} from "react"
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grow from '@material-ui/core/Grow';
import TextField from '@material-ui/core/TextField';
// import Grow from '@material-ui/core/Grow';
import WebFont from 'webfontloader';
import {navigate} from "gatsby"
import SEO from "../components/seo.js"
import Loader from '../components/WidgetComponents/Loader'
import Layout from "../components/layout"
import * as typeformEmbed from '@typeform/embed'
// import WaitlistAppBar from '../components/WidgetComponents/WaitlistAppBar.js'


if (typeof window !== `undefined`) {
  WebFont.load({
    google: {
      families: ['Work Sans:lighter,normal,bold,bolder', 'Lobster:lighter,normal,bold,bolder']
    }
  });
}

function Login(props) {
  const [isLoaded, setIsLoaded] = useState(false)
  const [innerHeight, setInnerHeight] = useState(null)
  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)
  const [email, setEmail] = useState(null)

  function openForm() {
    typeformEmbed.makePopup('https://solg.typeform.com/to/S6qaIN', {
      // mode: 'drawer_left',
      autoOpen: true,
      // autoClose: 3,
      hideScrollbars: true,
      onSubmit: function() {
        console.log('Typeform successfully submitted')
      }
    })
  }

  function handleSignOut() {
    // firebase
    //   .auth()
    //   .signOut();
    navigate('/')
  }

  useEffect(() => {
    if (!isLoaded) {
      if (typeof window !== `undefined`) {
        setInnerHeight(window.innerHeight)
        setIsLoaded(true)
      }
    }
  }, [isLoaded]);

  const page = (<Layout>
    <SEO title='Join Waitlist'/>
    <Grow in="in">
      <Grid container="container" direction="column" justify="center" alignItems="center" style={{
          backgroundColor: '',
          margin: '0px',
          height: innerHeight,
          // width: '100vw',
          padding: '0px 30px'
        }}>
        <Typography style={{
            fontFamily: 'Work Sans',
            fontWeight: 'bold',
            fontSize: '35px',
            paddingBottom: '30px',
            textAlign: 'center',
            lineHeight: 1.2
          }}>Hey, thanks for joining!</Typography>
        <Typography style={{
            fontFamily: 'Work Sans',
            fontWeight: 'bold',
            fontSize: '25px',
            paddingBottom: '30px',
            textAlign: 'center',
            lineHeight: 1.2
          }}>Looks like you don't have an invite code yet. {<span role="img" aria-label="awkward">😬</span>}</Typography>
        <Typography style={{
            fontFamily: 'Work Sans',
            fontWeight: 'normal',
            fontSize: '20px',
            paddingBottom: '20px',
            textAlign: 'center',
            lineHeight: 1.3,
            maxWidth: '820px'
          }}>QuickStyle is currently in beta. If you received an invite, go ahead and hit the link in the email. Otherwise, hop on the waitlist and you'll get access soon! {<span role="img" aria-label="rocket">🚀</span>}</Typography>
        <Grid container="container" direction='column' justify="center" alignItems="center" style={{
            // width: '300px'
          }}>
          <Grid style={{
              width: '300px',
              padding: '10px 0px 25px'
            }}>
            <TextField onChange={event => setFirstName(event.target.value)} variant='outlined' margin="normal" id="firstName" label="First Name" type="text" fullWidth="fullWidth" style={{
                margin: '15px 0px'
              }}/>
            <TextField onChange={event => setLastName(event.target.value)} variant='outlined' margin="normal" id="lastName" label="Last Name" type="text" fullWidth="fullWidth" style={{
                margin: '15px 0px'
              }}/>
            <TextField onChange={event => setEmail(event.target.value)} variant='outlined' margin="normal" id="email" defaultValue={props.email} label="Email Address" type="email" fullWidth="fullWidth" style={{
                margin: '15px 0px 5px'
              }}/>
          </Grid>
        </Grid>
        <Grid container="container" direction="row" wrap="wrap-reverse" justify="center" alignItems="center" style={{
            backgroundColor: ''
          }}>
          <Button color='' variant='outlined' size='large' style={{
              color: 'grey',
              margin: '15px'
            }} onClick={handleSignOut}>Back</Button>
          <Button color='secondary' variant='contained' size='large' onClick={() => openForm()}>Join the Waitlist</Button>
        </Grid>
      </Grid>
    </Grow>
  </Layout>)

  return (
    isLoaded
    ? page
    : <Loader/>)
}

export default Login

// Share your fonts and colour palette with designers, developers, marketers and everyone who's helping you build your brand.
